import React, { useContext } from 'react';
import { Socket } from 'socket.io-client';

interface ISocketContent {
  socket?: Socket;
}

function useSocket() {
  const { socket } = useContext(SocketContext);
  return { socket };
}

export const SocketContext = React.createContext<ISocketContent>({});

export default useSocket;
