import { CloudDownloadOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Drawer, List, Pagination, Spin, Tooltip } from "antd";
import { saveAs } from 'file-saver';
import { memo, useCallback, useEffect, useState } from "react";
import { DateTimeFormat } from "src/constants/common.constants";
import useSocket from "src/hooks/useSocket";
import { SOCKET_EVENTS } from "src/services/chat/chat.model";
import ExcelService from "src/services/excel";
import { EExcelStatus, EExcelType, IExcelHistory, IExcelHistoryReq, excelHistoryName, excelStatusColor } from "src/services/excel/excel.model";
import { convertDate } from "src/utils/dayjs";

interface IProps {
    onClose: () => void;
}

interface IState {
    refresh: boolean;
    searchText: string;
    showModal: boolean;
    pageSize: number;
    pageIndex: number;
    loading: boolean;
    total: number;
    data: IExcelHistory[];
    showModalDelete: boolean;
    status?: EExcelStatus;
    sortBy: string;
    order: string;
    showModalChangeStatus: boolean;
    selectedItem?: any;
    type: EExcelType;
}

const initState: IState = {
    refresh: false,
    searchText: '',
    showModal: false,
    pageSize: 13,
    pageIndex: 1,
    loading: true,
    total: 0,
    data: [],
    showModalDelete: false,
    sortBy: '',
    order: '',
    showModalChangeStatus: false,
    status: '' as any,
    type: '' as any,
};

function ExcelHistory({ onClose }: IProps) {
    const [state, setState] = useState<IState>(initState);
    const sv = new ExcelService();
    const { socket } = useSocket();

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.EXCEL_SUCCESS, (payload: { id: number; status: EExcelStatus }) => {
                setState((prev) => {
                    const exist = prev.data.find(x => x.id === payload.id);

                    if (!exist) {
                        return { ...prev, refresh: !prev.refresh }
                    }

                    return {
                        ...prev, data: prev.data.map(x => {
                            if (x.id === payload.id) return { ...x, loading: false, status: payload.status };

                            return x;
                        })
                    }
                });
            });
        }
    }, [socket?.connected]);

    useEffect(() => {
        fetchData();
    }, [state.refresh, state.pageIndex]);

    const fetchData = async () => {
        try {
            const res = await sv.history<IExcelHistoryReq, IExcelHistory>({
                pageSize: state.pageSize,
                pageIndex: state.pageIndex,
                status: state.status,
                type: state.type,
                searchText: state.searchText.trim(),
                // sortBy: state.sortBy,
                // order: state.order,
            });

            setState((prev) => ({ ...prev, data: res.data, total: res.total }));

        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
        }
    };

    // xử lý thay đổi trang
    const handleChangePage = useCallback((pageIndex: number, pageSize: number) => {
        setState((prev) => ({ ...prev, pageIndex, pageSize, refresh: !prev.refresh }));
    }, []);

    const handleDownload = (item: IExcelHistory) => {
        const name = excelHistoryName[item.type];
        saveAs(process.env.REACT_APP_API_URL + '/excel/export/' + item.name, name + '.xlsx');
    };

    return (
        <Drawer
            closable
            destroyOnClose
            title={<p>Lịch sử Excel</p>}
            placement="right"
            open
            onClose={onClose}
            footer={state.data.length > 0 && <Pagination onChange={handleChangePage} showSizeChanger={false} defaultCurrent={state.pageIndex} current={state.pageIndex} pageSize={state.pageSize} total={state.total} />}
        >
            <List>
                {state.data.map(item => {
                    const isInprogress = item.status === EExcelStatus.WAIT || item.loading;
                    return (
                        <List.Item
                            key={item.id}
                            actions={
                                isInprogress
                                    ? [<Tooltip title="Đang xử lý"><Spin className="text-xl" /></Tooltip>]
                                    : !item.isImport && item.status !== EExcelStatus.CANCEL
                                        ? [<Tooltip title="Tải xuống"><DownloadOutlined onClick={() => handleDownload(item)} className="text-xl cursor-pointer" /></Tooltip>]
                                        : undefined
                            }
                        >
                            <List.Item.Meta
                                avatar={<span className="text-2xl text-primary">{item.isImport ? <UploadOutlined /> : <CloudDownloadOutlined />}</span>}
                                title={<span style={{ color: excelStatusColor[item.status] }}>{excelHistoryName[item.type]}</span>}
                                description={`${item.isImport ? 'Nhập Excel' : 'Xuất Excel'} - ${convertDate(item.created_at, DateTimeFormat)}`}
                            />
                        </List.Item>

                    )
                })}
            </List>


        </Drawer>
    )
}

export default memo(ExcelHistory);