import { BaseService } from 'src/services/base.service';
import endpoints from 'src/services/config/endpoint';
import http from 'src/services/config/http';
import { IPaging } from '../base.model';
import { IOrderManagerReq } from '../order-manager/order-manager.model';
import {
  IAccount,
  IAccountParams,
  IAccountReq,
  IAdminDetailRes,
  IChangePassReq,
  IEnterForgotCode,
  ILoginData,
  ILoginDataPin,
} from './account.model';

const url = endpoints.account.default;
const urlAuth = endpoints.auth;
// const urlTrip = endpoints.trip_mamager;
const urlEnpoint = endpoints;

export default class AccountService extends BaseService<IAccount> {
  constructor() {
    super(url);
  }

  public async logIn(body: ILoginDataPin) {
    const { data } = await http.post<any>(urlAuth.login, body);
    return data;
  }
  public async checkLogIn(body: ILoginData) {
    const { data } = await http.post<any>(urlAuth.checkLogin, body);
    return data;
  }
  public async checkPhoneForgot(body: any) {
    const { data } = await http.post<any>(urlAuth.checkPhoneForgot, body);
    return data;
  }

  public async enterCodeForgot(body: IEnterForgotCode) {
    const { data } = await http.post<any>(urlAuth.enterCodeForgot, body);
    return data;
  }
  public async forgotPassword(body: any) {
    const { data } = await http.post<any>(urlAuth.forgotPassword, body);
    return data;
  }
  public async accountDeposit(params: IAccountParams) {
    const { data } = await http.get<any>(urlAuth.accountDeposit, { params });
    return data;
  }

  public async getSampleExcelFile() {
    const data = await http.get<any>(endpoints.excel.accountTemplate);
    return data;
  }

  public async importCustomer(fomData: FormData) {
    const data = await http.postForm<any>(endpoints.excel.importAccount, fomData);
    return data;
  }

  public async logOut() {
    await http.post(urlAuth.logout);
  }

  public async changePass(id: number, obj: IChangePassReq) {
    const { data } = await http.put<IAccount>(`${url}/change-pass/${id}`, obj);
    return data;
  }

  public async checkSession() {
    try {
      const { data } = await http.get(`${urlAuth.refresh}`);
      return data;
    } catch (err) {
      throw err?.response?.data || err;
    }
  }
  public async getListAdmin(params: IAccountParams) {
    const { data } = await http.get<IAccount, any>(`${url}/find-admin`, { params });
    return data;
  }
  // public async getDriverInfo(id: number) {
  //   const { data } = await http.get(`${urlTrip.driverInfo}/${id}`);
  //   return data;
  // }

  public async getAccountById(id: number) {
    const { data } = await http.get(`${urlEnpoint.account.find}/${id}`);
    return data;
  }
  public async getAllAccount(params: IPaging) {
    const { data } = await http.get(`${urlEnpoint.account.find}`, { params });
    return data;
  }


  public async getCustomerExport<ReqType>(params: ReqType) {
    const headers = { responseType: 'blob' };
    const data = await http.get(`${urlEnpoint.account.accountExport}`, {
      params,
      headers,
    });
    return data;
  }
  public async createAccount(accountModel: FormData) {
    const data = await http.postForm<IAccount, any>(`${urlAuth.add}`, accountModel);
    return data;
  }

  public async updateAccount(id: number, accountModel: FormData) {
    const data = await http.putForm<IAccount, any>(`${urlAuth.update}/${id}`, accountModel);
    return data;
  }

  public async statusAccount(id: number, params?: IAccountReq) {
    const { data } = await http.put<IAccountReq, any>(`${urlAuth.status}/${id}`, params);
    return data;
  }

  public async changePassword(params?: IChangePassReq) {
    const { data } = await http.put<IChangePassReq, any>(`${urlAuth.changePassword}`, params);
    return data;
  }

  public async deleteAccountCustomer(id: number) {
    const { data } = await http.delete(`${urlEnpoint.customer.default}/${id}`);
    return data;
  }
  public async getHistoryOrder(params: IOrderManagerReq) {
    const { data } = await http.get(`${urlEnpoint.orderManager.default}/find`, { params });
    return data;
  }
  public async getHistoryDebt(id: number, params: IOrderManagerReq) {
    const { data } = await http.get(`${urlEnpoint.account.default}/debt/${id}`, { params });
    return data;
  }

  public async findAdminDetail(id: number) {
    const { data } = await http.get<IAdminDetailRes>(`${urlEnpoint.account.findAdminDetail}/${id}`);
    return data;
  }

  public async getBillByIdDebt(id: number) {
    const { data } = await http.get(`${urlEnpoint.account.detailBill}/${id}`);
    return data;
  }
  public async getCodeCustomerList(params: IPaging) {
    const { data } = await http.get(`${urlEnpoint.getCode.customerList}`, { params });
    return data;
  }
}
