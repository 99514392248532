import { ISelect } from './common.enum';

export class TextUtils {
  static isEmpty(text?: string): boolean {
    if (!text) return true;
    if (typeof text === 'undefined') return true;
    if (text === 'undefined') return true;
    if (text.trim() === '') return true;

    return false;
  }

  static formatName = (str: string): string => {
    return str.replace(/\s{2,}/g, ' ').trim();
  };

  static removeDialCodePhone = (phone: string, dialCode: string): string => {
    return phone.replace(/ /g, '').replace(`+${dialCode}`, '').replace(/^0/, '').trim();
  };
  static _formatPrice = (num: string | number) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  /**
   * Chuyển đổi ID sang :id
   * Ví dụ: edit/staff/5fc852d82036c454f8a57a05 -> edit/staff/:id
   * @param text
   */
  static transformIdPath = (text: string) => {
    return text.replace(/[0-9]{1,}$/, ':id');
  };

  static formatNumberPrice = (numberPrice: string | number = 0) => {
    return (numberPrice || '0').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };
  static formatNumberPriceInputNumber = (numberPrice: string | number = 0) => {
    const formattedNumber = (numberPrice || '').toString();
    // Chèn dấu phẩy giữa các chữ số hàng nghìn
    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  static decodeEntities = (() => {
    const element = document.createElement('div');

    const decodeHTMLEntities = (str: any) => {
      if (str && typeof str === 'string') {
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
      }

      return str;
    };

    return decodeHTMLEntities;
  })();

  static truncate = (str: string, truncate: number) => {
    return str?.length > truncate ? str.substring(0, truncate) + '...' : str;
  };

  static getValueByKey = (e: any, value: string) => {
    return Object.entries(e).find(([key, val]) => key === value)?.[1];
  };
  static getKeyByValue = (e: any, value: string | number) => {
    return (Object.keys(e) as (keyof typeof e)[]).find((key) => e[key] === value);
  };

  static enumToOptions = (
    e: any,
    useDefault: boolean = true,
    defaultValue: string = 'all',
    defaultString: string = '',
  ) => {
    const _options = [];
    if (useDefault) {
      _options.push({
        value: defaultValue,
        label: defaultString,
      });
    }
    Object.keys(e).map((value) => {
      _options.push({
        value,
        label: e[value as keyof typeof e],
      });
    });
    return _options;
  };
  static optionToOptions = (
    option: ISelect[],
    useDefault: boolean = true,
    defaultValue: string = '',
    defaultString: string = '',
  ) => {
    const _options = [];
    if (useDefault) {
      _options.push({
        value: defaultValue,
        label: defaultString,
      });
    }
    option.map((value) => {
      return _options.push({
        value: value.value,
        label: value.label,
      });
    });
    return _options;
  };
  static removeHtmlTags = (str: string = '') => {
    str = str.replace(/(<([^>]+)>)/gi, '');
    str = str.replace(/\&nbsp;/g, '');
    str = str.trim();
    return str;
  };

  static renderHourOfDay = () => {
    const _data = [];
    for (let i = 1; i <= 24; i++) {
      _data.push(i.toString());
    }
    return {
      categories: _data,
      title: {
        text: 'Giờ',
      },
    };
  };

  static validEditorContent = (str: string, min: number, max: number) => {
    const content = this.removeHtmlTags(str);
    if (content.length < min || content.length > max) return false;
    else return true;
  };
}
