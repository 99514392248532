/* eslint-disable no-empty */
export enum KeyStorage {
  AUTH = 'auth',
  TOKEN = 'token',
  REFRESH_TOKEN = 'refresh_token',
  LOCALE = 'locale',
  USER = 'user',
  CART = 'CART',
}

const localStorageHelper = {
  set: (key: KeyStorage, value: string): boolean => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (error) {
      // console.log('error: ', error);
    }
    return false;
  },
  setObject: (key: KeyStorage, value: unknown): boolean => {
    try {
      const newValue = JSON.stringify(value);
      localStorage.setItem(key, newValue);
      return true;
    } catch (error) {
      // console.log('error: ', error);
    }
    return false;
  },
  get: (key: KeyStorage, defaultValue: string | null = null): string | null => {
    try {
      const value = localStorage.getItem(key);
      if (value) {
        return value;
      }
    } catch (error) {
      // console.log('error: ', error);
    }
    return defaultValue;
  },
  getObject: (key: KeyStorage, defaultValue: unknown = {}): any => {
    try {
      const value = localStorage.getItem(key);
      if (value) {
        const object = JSON.parse(value);
        return object;
      }
    } catch (error) {
      // console.log('error: ', error);
    }
    return defaultValue;
  },
  remove: (key: KeyStorage) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
};

export default localStorageHelper;
