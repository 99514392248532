import { useCallback } from 'react';
import { IAccount } from 'src/services/account/account.model';
import { IPermissionRes } from 'src/services/group-admin/groupAdmin.model';
import { useAppDispatch, useAppSelector } from 'src/stores';
import { setAccount } from 'src/stores/auth/auth.slice';
import { ROLE_AMIN } from 'src/utils';
import { EventBus, EventBusName } from 'src/utils/event-bus';
import { EPermissionScreen, EPermissionType } from 'src/utils/permissions';

const useAuth = () => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const setAuth = useCallback((body: IAccount) => {
    dispatch(setAccount(body));
  }, []);

  const logout = useCallback(() => {
    dispatch(setAccount(undefined));
    EventBus.getInstance().post({
      type: EventBusName.LOGOUT,
      payload: true,
    });
  }, []);

  const checkActivePermission = useCallback(
    (screen: EPermissionScreen, type?: EPermissionType) => {
      const checkPermission = type ? screen + '_' + type : screen;
      const user = auth?.account;

      if (!user) return false;

      // Bỏ với role admin
      if (user.group_account_id === ROLE_AMIN) {
        return true;
      }

      if (!user.account_permission) return false;

      const accountPermissons = user.account_permission.map((x: IPermissionRes) => x.permission_id);

      const activePermissonsExist = accountPermissons.find((x) => {
        return (x === checkPermission)
      });
      return !!activePermissonsExist;
    },
    [auth?.account],
  );

  return {
    setAuth,
    user: auth?.account,
    logout,
    checkActivePermission,
    isRoleAdmin: auth?.account?.group_account_id === ROLE_AMIN,
  };
};

export default useAuth;
