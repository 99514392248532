import { Modal } from 'antd';
import React, { createContext, useContext, useState } from 'react';
import { BaseProps } from 'src/utils';

interface Props {
  onShowModalConfirm: (payload: { title: string; content: string; onSubmit: any; id?: string }) => void;
}

const ModalConfirmContext = createContext<Props>({
  onShowModalConfirm: (payload: { title: string; content: string; onSubmit: any; id?: string }) => undefined,
});

export const useModalConfirm = () => useContext(ModalConfirmContext);

const ModalConfirmProvider = (props: BaseProps) => {
  const [state, setState] = useState<any>({
    open: false,
    title: '',
    content: '',
    id: '',
    onSubmit: async (id?: string) => undefined,
  });

  const onOk = async () => {
    const res = await state.onSubmit(state.id);
    if (res) {
      setState({ ...state, open: false });
    }
  };

  const contextValue: Props = {
    onShowModalConfirm: (payload: { title: string; content: string; onSubmit: any; id?: string }) => {
      setState({
        open: true,
        title: payload.title,
        content: payload.content,
        id: payload.id,
        onSubmit: payload.onSubmit,
      });
    },
  };

  return (
    <ModalConfirmContext.Provider value={contextValue}>
      {props.children}
      <Modal
        width={400}
        title={state.title}
        okText="Có"
        cancelText="Hủy"
        onCancel={() => setState({ ...state, open: false })}
        onOk={onOk}
        open={state.open}
      >
        <p style={{ padding: 20 }}>{state.content}</p>
      </Modal>
    </ModalConfirmContext.Provider>
  );
};

export default React.memo(ModalConfirmProvider);
