import { AliasToken } from 'antd/es/theme/internal';
import { ITreePermission } from 'src/services/group-admin/groupAdmin.model';

export interface BaseProps {
  children: any;
}

export const formatter2 = new Intl.NumberFormat('en-US', { maximumFractionDigits: 3 });

export const themeToken: Partial<AliasToken> | undefined = {
  fontSize: 14,
  fontFamily: '"Verdana", sans-serif',
  sizeStep: 3,
  sizeUnit: 3,
  colorPrimary: '#0099FF',
  colorInfo: '#0099FF',
};

export interface IBaseOption {
  value: any;
  label: string;
  data?: any;
}

export function hexToUtf8(hex = '') {
  if (!hex) return '';
  // @ts-ignore
  return decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'));
}

export const formatPrice = (price = 0) => {
  return Intl.NumberFormat('vi-VN').format(price || 0);
};

const numberToWords = (num: any) => {
  const units = ['', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];
  const teens = [
    'mười',
    'mười một',
    'mười hai',
    'mười ba',
    'mười bốn',
    'mười lăm',
    'mười sáu',
    'mười bảy',
    'mười tám',
    'mười chín',
  ];

  const tens = ['', '', 'hai mươi', 'ba mươi', 'bốn mươi', 'năm mươi', 'sáu mươi', 'bảy mươi', 'tám mươi', 'chín mươi'];
  const scales = ['', 'nghìn', 'triệu', 'tỷ'];

  if (num === 0) return 'không';

  const getHundreds = (n: any) => {
    let hundred = Math.floor(n / 100);
    let rest = n % 100;
    let words = [];

    if (hundred > 0) {
      words.push(units[hundred]);
      words.push('trăm');
    }

    if (rest >= 20) {
      let ten = Math.floor(rest / 10);
      let unit = rest % 10;
      words.push(tens[ten]);
      if (unit > 0) {
        words.push(units[unit]);
      }
    } else if (rest >= 10) {
      words.push(teens[rest - 10]);
    } else if (rest > 0) {
      words.push(units[rest]);
    }

    return words.join(' ');
  };

  let parts = [];
  let scale = 0;

  while (num > 0) {
    let part = num % 1000;
    if (part > 0 || scale === 0) {
      let words = getHundreds(part);
      if (scale > 0) {
        words += ` ${scales[scale]}`;
      }
      parts.unshift(words);
    } else {
      parts.unshift('');
    }
    num = Math.floor(num / 1000);
    scale++;
  }

  // Remove empty strings and join parts
  return parts.filter(Boolean).join(' ');
};

export const convertCurrencyToWords = (amount = 0) => {
  const amountInWords = numberToWords(amount);
  return `${amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1)} đồng`;
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) return '';
  const numericString = phoneNumber.replace(/\D/g, '');

  if (numericString.length < 10) {
    return phoneNumber;
  }

  // Định dạng số điện thoại theo "xxxx.xxx.xxx"
  const formattedNumber = `${numericString.substring(0, 4)}.${numericString.substring(4, 7)}.${numericString.substring(
    7,
  )}`;
  return formattedNumber;
};

// This function keeps the casing unchanged for str, then perform the conversion
export function toNonAccentVietnamese(str: string = '') {
  if (typeof str !== 'string') str = String(str);
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
}

export function baseFilterOpts(input: string, options: any) {
  try {
    const value = toNonAccentVietnamese(options.props.label);
    return (
      value.indexOf(input.toLowerCase()) >= 0 || options.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  } catch (error) {
    return true;
  }
}

export function filterInArr(input: string, arr: string[]) {
  return arr.filter((item) => {
    const value = toNonAccentVietnamese(item);
    return value.indexOf(input.toLowerCase()) >= 0;
  });
}

// tài khoản fake
export const testPhoneApple = '0900000000';
export const testPhoneGoogle = '0900000001';
export const testPhones = [testPhoneApple, testPhoneGoogle];
export const supperAdminPhones = ['0978789177', '0335649001'];

export const makeid = (length = 8) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const convertImage = (image?: string, defaultImage = '/images/chat/logoChat.svg') => {
  if (!image) return defaultImage;
  return process.env.REACT_APP_STATIC_URL + image;
};

export const extractKeys = (permissions: ITreePermission[]) => {
  let keys: string[] = [];
  permissions.forEach((permission) => {
    keys.push(permission.key);
    if (permission.children) {
      keys = keys.concat(extractKeys(permission.children));
    }
  });
  return keys;
};

export const ROLE_AMIN = 'admin';
