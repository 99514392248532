import { BasePagingRes } from '../base.model';
import endpoint from '../config/endpoint';
import http from '../config/http';

export default class ExcelService {
  public async export<IParams, ResType>(url: string, params?: IParams) {
    const { data } = await http.get(url, { params });
    return data;
  }

  public async history<Req, ResType>(params?: Req) {
    const { data } = await http.get<BasePagingRes<ResType>>(endpoint.excel.history, { params });
    return data;
  }
}
