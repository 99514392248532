import { Avatar, Menu, MenuProps } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { split } from 'lodash';
import 'moment/locale/vi';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { homeRouter, mapsRoutePermissions, routes } from 'src/routers';
import { supperAdminPhones } from 'src/utils';
import { LIST_ACTION_IGNORE_ACTIVE_MENU } from 'src/utils/common.enum';
import { EPermissionType } from 'src/utils/permissions';

interface IProps {
  collapsed?: boolean;
  isMobile?: boolean;
  onCloseMenu?: () => void;
}

const MenuConponent = ({ collapsed, isMobile, onCloseMenu }: IProps) => {
  const location = useLocation();
  const navigation = useNavigate();
  const [openKeys, setOpenKeys] = useState(['/']);
  const [selectedKey, setSelectedKey] = useState('/');
  const [parentMenuKey, setParentMenuKey] = useState<string[]>([]);
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production';

  const { user, isRoleAdmin } = useAuth();

  const availableRouters = useMemo(() => {
    if (isRoleAdmin || supperAdminPhones.includes(user?.phone!)) return routes;

    if (!user || !routes?.length || !user?.group_account_id) {
      return [homeRouter];
    }

    if (!user?.account_permission?.length || !routes?.length) {
      return [homeRouter];
    }

    const permissions = user.account_permission.map((x) => x.permission_id);

    const regStr = Object.values(EPermissionType)
      .map((x) => '_' + x)
      .join('|');
    const reg = new RegExp(regStr, 'g');

    const filteredMenuItems = routes.filter((item: any) => {
      if (item.key === '/') return true;
      const activePermissions: string[] = mapsRoutePermissions[item.key];

      const allActivePermissonsExist = permissions.some((permission) => {
        return activePermissions.includes(permission.replace(reg, ''));
      });

      return allActivePermissonsExist;
    });

    return filteredMenuItems.map((item) => {
      if (item.children?.length) {
        item.children = item.children.filter((x) => {
          return permissions.some((p) => {
            return x.permission && x.permission.includes(p);
          });
        });
      }

      return item;
    });
  }, [routes, user]);

  useEffect(() => {
    if (location.pathname !== '/') {
      let _submenu: string[] = [];
      let _string = '';
      _submenu = split(location.pathname, '/').filter((url) => {
        if (url !== '' && !LIST_ACTION_IGNORE_ACTIVE_MENU.includes(url) && !Number(url)) {
          return url;
        }
      });
      _submenu.map((item) => {
        _string = _string + '/' + item;
      });
      setOpenKeys(['/' + _submenu[0]]);
      setSelectedKey(_string);
    } else {
      setOpenKeys(['/']);
      setSelectedKey('/');
    }
    const routeKeys: string[] = [];
    if (availableRouters !== undefined && availableRouters.length > 0) {
      for (const route of availableRouters) {
        routeKeys.push(route?.key?.toString()!);
      }
    }

    setParentMenuKey(routeKeys);
  }, [location.pathname, availableRouters]);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (parentMenuKey.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick: MenuProps['onClick'] = (e) => {
    navigation(e.key);
    onCloseMenu?.();
  };

  const renderLogo = useMemo(() => {
    if (collapsed) {
      return <Avatar className="short-logo" src="/assets/favicon.png" alt="avatar" size={35} />;
    }

    if (isDev) {
      return <h1 className="text-logo">Reikatachi Dev</h1>;
    }

    // return <img className='large-logo' src="/assets/icLogo.png" alt="logo" />
    return <h1 className="text-logo">Reikatachi App</h1>;
  }, [collapsed, isDev]);

  if (isMobile) {
    return (
      <Menu
        mode="inline"
        openKeys={openKeys}
        selectedKeys={[selectedKey]}
        items={availableRouters}
        onClick={onClick}
        onOpenChange={onOpenChange}
      />
    );
  }

  return (
    <Header className="p-t-10">
      <div className="logo">{renderLogo}</div>
      <Menu
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        selectedKeys={[selectedKey]}
        items={availableRouters}
        // onClick={onClick}
        onOpenChange={onOpenChange}
      />
    </Header>
  );
};

export default MenuConponent;
