import { FileExcelOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-components';
import { FloatButton, Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import ModalConfirmProvider from 'src/libraries/modal/ModalConfirm';
import ExcelHistory from './ExcelHistory';
import HeaderComponent from './header';
import MenuConponent from './menu';

const LayoutClient = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const handleToggleCollapsed = useCallback(() => {
    // todo: save to local storage
    setCollapsed((prev) => !prev);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={250} collapsed={collapsed} className="fixed-sidebar">
        <MenuConponent collapsed={collapsed} />
      </Sider>
      <Layout className="site-layout">
        <ModalConfirmProvider>
          <Content>
            {/* page header */}
            <HeaderComponent collapsed={collapsed} onToggleCollapsed={handleToggleCollapsed} />
            {/* page content */}
            <PageContainer>
              <Outlet />
            </PageContainer>
          </Content>
        </ModalConfirmProvider>
      </Layout>

      <FloatButton
        className="ic-history"
        shape="circle"
        type="primary"
        style={{ right: 5 }}
        icon={<FileExcelOutlined />}
        onClick={() => setOpenDrawer(true)}
      />

      {openDrawer && <ExcelHistory onClose={() => setOpenDrawer(false)} />}

    </Layout>
  );
};

export default React.memo(LayoutClient);
