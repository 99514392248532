import { ConfigProvider, Typography } from 'antd';
import 'antd/dist/reset.css';
import locale from 'antd/locale/vi_VN';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import 'moment/locale/vi';
import React, { Suspense, lazy, useEffect, useRef } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Subscription } from 'rxjs';
import Layout from './components/layout';
import LayoutMobile from './components/layout/layoutMobile';
import SpinnerLoader from './components/loader';
import { ConfirmProvider } from './hooks/useConfirm';
import { useIsMobile } from './hooks/useIsMobile';
import { SocketProvider } from './hooks/useSocket/handling';
import useToast from './hooks/useToast';
import { ToastProvider } from './hooks/useToast/handling';
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './routers/app';
import { ROUTE_CONFIG } from './routers/helpers';
import PrivateRoute from './routers/privateRoute';
import { persistor, store } from './stores';
import './styles/app.scss';
import { themeToken } from './utils';
import { EventBus, EventBusName } from './utils/event-bus';
dayjs.locale('vi');
dayjs.extend(weekday);
dayjs.extend(localeData);

const Page404Screen = lazy(() => import('./screens/errors/page404'));

function RootComponent(props: { children: React.ReactNode }) {
  const subscriptions = useRef<any>();
  const toast = useToast();
  const navigation = useNavigate();

  useEffect(() => {
    registerEventBus();

    return () => {
      subscriptions.current?.unsubscribe();
    };
  }, []);

  const handleLogout = () => {
    toast.showError('Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại!');
    navigation(ROUTE_CONFIG.LOGIN);
  };

  const handleAlertLogout = () => {
    toast.showSuccess('Đăng xuất tài khoản thành công!');
    navigation(ROUTE_CONFIG.LOGIN);
  };

  const handleForbidden = () => {
    toast.showError('Bạn không có quyền sử dụng tính năng này!');
  };

  function registerEventBus(): void {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: any) => {
        switch (data.type) {
          case EventBusName.INVALID_TOKEN:
            handleLogout();
            break;
          case EventBusName.FORBIDDEN:
            handleForbidden();
            break;
          case EventBusName.LOGOUT:
            handleAlertLogout();
            break;
          default:
            break;
        }
      }),
    );
  }

  return <div>{props.children}</div>;
}

const App = () => {
  const isMobile = useIsMobile();

  const customizeRenderEmpty = () => <Typography.Text>Dữ liệu không tồn tại!</Typography.Text>;

  return (
    <Provider store={store}>
      <PersistGate loading={<SpinnerLoader />} persistor={persistor}>
        <CookiesProvider>
          <ToastProvider>
            <ConfirmProvider>
              <SocketProvider>
                <Suspense fallback={<SpinnerLoader />}>
                  <ConfigProvider locale={locale} renderEmpty={customizeRenderEmpty} theme={{ token: themeToken }}>
                    <RootComponent>
                      <Routes>
                        {/* Private route */}
                        <Route
                          element={
                            <PrivateRoute roles={['user']}>{isMobile ? <LayoutMobile /> : <Layout />}</PrivateRoute>
                          }
                        >
                          {authProtectedFlattenRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                          ))}
                        </Route>

                        {/* page without authen */}
                        <Route>
                          {publicProtectedFlattenRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                          ))}
                        </Route>

                        <Route path="*" element={<Page404Screen />} />
                      </Routes>
                    </RootComponent>
                  </ConfigProvider>
                </Suspense>
              </SocketProvider>
            </ConfirmProvider>
          </ToastProvider>
        </CookiesProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
