import React, { useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { BaseProps } from 'src/utils';
import localStorageHelper, { KeyStorage } from 'src/utils/localStorage';
import { SocketContext } from '.';
import useAuth from '../useAuth';

const Component = (props: BaseProps) => {
  const [socket, setSocket] = useState<Socket>();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.id) {
      const token = `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}`;
      const socketPath = process.env.REACT_APP_SOCKET_PATH;
      const socket = io(socketPath as string, {
        auth: { token },
        transports: ['websocket'],
      });
      setSocket(socket);
    }
  }, [user?.id]);

  const contextValue = {
    socket,
  };

  return <SocketContext.Provider value={contextValue}>{props.children}</SocketContext.Provider>;
};

const SocketProvider = React.memo(Component);

export { SocketProvider };
