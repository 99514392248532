import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Space } from "antd";
import React, { createContext, useContext, useMemo, useState } from "react";
import { BaseProps } from "src/utils";

interface IConfirm {
  title?: string;
  message?: string;
  onOk?: () => void;
  onClose?: () => void;
  btnCancelText?: string;
  btnConfirmText?: string;
  width?: number;
  showId?: boolean;
  selectedId?: number | string;
  chilComponent?: React.ReactElement;
}

interface IState extends IConfirm {
  showConfirm: boolean;
}

interface IConfirmContext {
  toogleConfirm: (value: IConfirm) => void;
}

const ConfirmContext = createContext<IConfirmContext>({
  toogleConfirm: (value: IConfirm) => {
    throw new Error(value.message);
  },
});

export const useConfirm = () => useContext(ConfirmContext);

const initState: IState = {
  showConfirm: false,
  title: "Xác nhận",
  message: "Bạn chắc chắn muốn xóa bản ghi hiện tại?",
}

function Component({ children }: BaseProps) {
  const [state, setState] = useState<IState>(initState);

  const contextValue = useMemo(
    () => ({
      toogleConfirm: (state: IConfirm) =>
        setState((prevState) => ({
          ...state,
          showConfirm: true,
        })),
    }),
    []
  );

  const onOk = () => {
    setState(initState);
    if (state.onOk) state.onOk();
  };

  const onClose = () => {
    setState(initState);
    if (state.onClose) state.onClose();
  };


  return (
    <ConfirmContext.Provider value={contextValue}>
      {state.showConfirm && (
        <div>
          <Modal
            title={
              <>
                <QuestionCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> {state.title}
              </>
            }
            centered
            open
            onCancel={onClose}
            width={state.width || 400}
            style={{ minHeight: '200px', width: '400px !important', borderRadius: '2px !important' }}
            footer={[
              <Row justify="end" key="a">
                <Space wrap={true}>
                  <Button
                    key="back"
                    className="btn_cancel"
                    style={{ minWidth: '80px', marginRight: 10 }}
                    onClick={onClose}
                  // loading={loadingSubmit}
                  >
                    {state.btnCancelText || 'Huỷ'}
                  </Button>
                  <Button
                    key="submit"
                    // loading={loadingSubmit}
                    className="btn_submit"
                    style={{ minWidth: '80px' }}
                    onClick={onOk}
                  >
                    {state.btnConfirmText || 'Xác nhận'}
                  </Button>
                </Space>
              </Row>,
            ]}
          >
            <div
              style={{
                minHeight: '80px',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                textAlign: 'center',
                display: 'flex',
              }}
            >
              {state.message || ''}
              {state.chilComponent} {state.showId ? state.selectedId : ''}
            </div>
          </Modal>
        </div>
      )}
      {children}
    </ConfirmContext.Provider>
  );
}

const ConfirmProvider = React.memo(Component);

export { ConfirmProvider };
