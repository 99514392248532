import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccount } from 'src/services/account/account.model';
import { AuthState } from './auth.state';

const initialState: AuthState = Object.freeze({
  isAuthenticated: false,
  status: 'idle',
  account: undefined,
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, { payload }: PayloadAction<IAccount | undefined>) => ({
      ...state,
      account: payload,
    }),
  },
});

export const { setAccount } = authSlice.actions;

export default authSlice.reducer;
