import { HomeOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-components';
import { Breadcrumb, Drawer, Layout } from 'antd';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { SubMenuType } from 'antd/es/menu/hooks/useItems';
import { split } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { routes } from 'src/routers';
import { EBreadCrumd, EBreadCrumdText } from 'src/utils/breadcrumd';
import { TextUtils } from 'src/utils/textUtils';
import HeaderComponent from './header';
import MenuConponent from './menu';
const LayoutMobile = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const location = useLocation();
  const handleToggleCollapsed = useCallback(() => {
    // todo: save to local storage
    setCollapsed((prev) => !prev);
  }, []);
  const getDefaultMenu = () => {
    const currentPath = TextUtils.transformIdPath(window.location.pathname);
    let parentRoute: SubMenuType | undefined;
    let subRoute: SubMenuType | undefined;
    if (!routes?.length) {
      return {
        parentRoute,
        subRoute,
      };
    }
    const routerCount = routes.length;

    for (let index = 0; index < routerCount; index++) {
      const route = routes[index];

      // @ts-ignore
      const children: MenuProps['items'] = route?.children || [];

      if (children && children.length > 0) {
        const childRoute = children.find((it: any) => {
          const arrayItem = currentPath.split('/');
          const lastItem = arrayItem[arrayItem.length - 1];
          const path = it?.key as string;

          // replace id item sang :id
          const itemRemoveEndPath = currentPath.substring(0, currentPath.length - lastItem.length) + ':id';

          return path.includes(':id') ? path === itemRemoveEndPath : path === currentPath;
        });
        if (childRoute) {
          parentRoute = route as SubMenuType;
          subRoute = childRoute as SubMenuType;
          break;
        }
      } else {
        if (route?.key === currentPath) {
          parentRoute = route as SubMenuType;
          break;
        }
      }
    }

    if (!!subRoute) {
      if (subRoute.key === parentRoute?.key) {
        subRoute = undefined;
      }
    }

    return {
      parentRoute,
      subRoute,
    };
  };

  const { parentRoute, subRoute } = useMemo(() => {
    return getDefaultMenu();
  }, [location.pathname, routes]);

  const pageTitle = useMemo(() => {
    let name = parentRoute?.label || 'Trang quản trị';
    if (subRoute?.label) {
      name = subRoute?.label;
    }
    return name;
  }, [parentRoute, subRoute, location.pathname]);
  const breadcrumbItems = useMemo(() => {
    const items: ItemType[] = [];
    let _submenu = [];
    let _string = '';
    let _breadCrumdKey = '';
    let _breadCrumdTitle = '';

    _submenu = split(location.pathname, '/').filter((br) => br !== '');
    _submenu.map((item) => {
      if (!Number(item)) {
        _string = _string + '/' + item;
      }
    });
    _breadCrumdKey = TextUtils.getKeyByValue(EBreadCrumd, _string) as any;
    _breadCrumdTitle = TextUtils.getValueByKey(EBreadCrumdText, _breadCrumdKey) as any;

    items.push({ title: _breadCrumdTitle, key: _breadCrumdKey });
    return items;
  }, [parentRoute, subRoute, location.pathname]);
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderComponent collapsed={collapsed} onToggleCollapsed={handleToggleCollapsed} isMobile={isMobile} />
      {/* page content */}
      <PageContainer>
        <div style={{ height: 40, position: 'relative' }}>
          <div className="d-flex breadcrumb-mobile">
            <HomeOutlined /> <span className="m-t-4 p-x-2"> / </span>
            {isMobile && <Breadcrumb items={breadcrumbItems} />}
          </div>
        </div>
        <Outlet />
      </PageContainer>

      <Drawer title="Menu" placement="left" onClose={handleToggleCollapsed} open={collapsed}>
        <MenuConponent onCloseMenu={handleToggleCollapsed} isMobile={isMobile} />
      </Drawer>
    </Layout>
  );
};

export default React.memo(LayoutMobile);
