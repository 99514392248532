import Axios from 'axios';
import { EventBus, EventBusName } from 'src/utils/event-bus';
import localStorageHelper, { KeyStorage } from 'src/utils/localStorage';

const isDEV = process.env.REACT_APP_ENVIRONMENT === 'development';
const REQ_TIMEOUT = 30 * 1000;

const http = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    apptype: 'CMS',
    authorization: `Bearer ${localStorage.getItem('TOKEN')}`,
    lang: 'vi',
    lg: 'vi',
  },
  timeout: REQ_TIMEOUT,
  // withCredentials: true,
});

http.interceptors.request.use((request) => {
  if (request.headers) {
    request.headers.authorization = `Bearer ${localStorageHelper.get(KeyStorage.TOKEN) || ''}  `;
    // request.headers.refreshtoken = localStorageHelper.get(KeyStorage.REFRESH_TOKEN) || '';
  }
  return request;
});

/**
 * intercepts any response and checks the response from our api for a 401 status in the response. ie. the token has now expired and is no longer valid, or no valid token was sent.
 * If such a status exists then we log out the user and clear the profile from redux state.
 */
http.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.status === 401) {
      // window.location.href = '/login';
      EventBus.getInstance().post({
        type: EventBusName.INVALID_TOKEN,
      });
    }
    return Promise.reject(err);
  },
);

export default http;
