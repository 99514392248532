import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Avatar, Breadcrumb, Button, Dropdown, Form, Input, MenuProps, Modal, Row, Space } from 'antd';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { Rule } from 'antd/es/form';
import { Header } from 'antd/es/layout/layout';
import type { SubMenuType } from 'antd/es/menu/hooks/useItems';
import { FormInstance } from 'antd/lib';
import { split } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import useToast from 'src/hooks/useToast';
import { routes } from 'src/routers';
import { ROUTE_CONFIG } from 'src/routers/helpers';
import AccountService from 'src/services/account';
import { IAuthState } from 'src/services/account/account.model';
import { EBreadCrumd, EBreadCrumdText } from 'src/utils/breadcrumd';
import localStorageHelper, { KeyStorage } from 'src/utils/localStorage';
import { TextUtils } from 'src/utils/textUtils';

interface IProps {
  collapsed: boolean;
  onToggleCollapsed: () => void;
  isMobile?: boolean;
}

function HeaderComponent({ collapsed, onToggleCollapsed, isMobile }: IProps) {
  const { user, logout } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const [state, setState] = useState({ loading: false });
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const formRef = React.createRef<FormInstance>();
  const toast = useToast();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleTopMenu);
  });

  const handleTopMenu = (e: any) => {
    // console.log('e: ', e);
  };

  const onAvatarClicked = () => {
    // history.push(`/manager/admin/general`);
  };

  const getDefaultMenu = () => {
    const currentPath = TextUtils.transformIdPath(window.location.pathname);
    let parentRoute: SubMenuType | undefined;
    let subRoute: SubMenuType | undefined;
    if (!routes?.length) {
      return {
        parentRoute,
        subRoute,
      };
    }
    const routerCount = routes.length;

    for (let index = 0; index < routerCount; index++) {
      const route = routes[index];

      // @ts-ignore
      const children: MenuProps['items'] = route?.children || [];

      if (children && children.length > 0) {
        const childRoute = children.find((it) => {
          const arrayItem = currentPath.split('/');
          const lastItem = arrayItem[arrayItem.length - 1];
          const path = it?.key as string;

          // replace id item sang :id
          const itemRemoveEndPath = currentPath.substring(0, currentPath.length - lastItem.length) + ':id';

          return path.includes(':id') ? path === itemRemoveEndPath : path === currentPath;
        });
        if (childRoute) {
          parentRoute = route as SubMenuType;
          subRoute = childRoute as SubMenuType;
          break;
        }
      } else {
        if (route?.key === currentPath) {
          parentRoute = route as SubMenuType;
          break;
        }
      }
    }

    if (!!subRoute) {
      if (subRoute.key === parentRoute?.key) {
        subRoute = undefined;
      }
    }

    return {
      parentRoute,
      subRoute,
    };
  };

  const { parentRoute, subRoute } = useMemo(() => {
    return getDefaultMenu();
  }, [location.pathname, routes]);

  const pageTitle = useMemo(() => {
    let name = parentRoute?.label || 'Trang quản trị';
    if (subRoute?.label) {
      name = subRoute?.label;
    }
    return name;
  }, [parentRoute, subRoute, location.pathname]);

  const breadcrumbItems = useMemo(() => {
    const items: ItemType[] = [];
    let _submenu = [];
    let _string = '';
    let _breadCrumdKey = '';
    let _breadCrumdTitle = '';

    _submenu = split(location.pathname, '/').filter((br) => br !== '');
    _submenu.map((item) => {
      if (!Number(item)) {
        _string = _string + '/' + item;
      }
    });
    _breadCrumdKey = TextUtils.getKeyByValue(EBreadCrumd, _string) as any;
    _breadCrumdTitle = TextUtils.getValueByKey(EBreadCrumdText, _breadCrumdKey) as any;

    items.push({ title: _breadCrumdTitle, key: _breadCrumdKey });
    return items;
  }, [parentRoute, subRoute, location.pathname]);

  const menuItems: MenuProps['items'] = [
    {
      key: 'changePassword',
      icon: <UserSwitchOutlined />,
      label: 'Đổi mật khẩu Admin',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Đăng xuất',
    },
  ];

  const handleShowLogout: MenuProps['onClick'] = (info) => {
    if (info.key === 'logout') {
      setOpenModal(true);
    }
  };

  const handleShowchangePassword: MenuProps['onClick'] = (info) => {
    if (info.key === 'changePassword') {
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setState((prev) => ({ ...prev, loading: false }));
  };

  const handleLogout = () => {
    setState((prev) => ({ ...prev, loading: true }));
    setTimeout(() => {
      setState((prev) => ({ ...prev, loading: false }));
      logout();
    }, 500);
  };

  const renderPageTitle = useMemo(() => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle} - reikatachi</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
    );
  }, [pageTitle]);

  const handleCancelChangePassword = () => {
    setOpen(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const body = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const sv = new AccountService();
      const data = await sv.changePassword(body);
      if (!data.statusCode && !data.message) {
        const userData: IAuthState = {
          user: data,
          refreshToken: data.refreshToken,
          token: data.accessToken,
        };
        localStorageHelper.set(KeyStorage.TOKEN, data.accessToken);
        localStorageHelper.setObject(KeyStorage.USER, userData);

        toast.showSuccess('Đổi mật khẩu thành công');
        navigation(ROUTE_CONFIG.LOGIN);
      }
    } catch (err) {
      toast.showError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const validation: { [key: string]: Rule[] } = {
    oldPassword: [{ required: true, message: 'Nhập mật khẩu cũ!' }],
    newPassword: [{ required: true, message: 'Nhập mật khẩu mới!' }],
    reEnterNewPassword: [
      {
        required: true,
        message: 'Nhập lại mật khẩu mới!',
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('newPassword') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Mật khẩu không khớp!'));
        },
      }),
    ],
  };

  return (
    <>
      {/* {renderPageTitle} */}
      <Header className="flex-navbar main-header">
        <div className="left">
          <Button type="link" className="ic-toggle" onClick={onToggleCollapsed}>
            {!collapsed ? (
              <MenuFoldOutlined style={{ fontSize: 18 }} />
            ) : (
              <MenuUnfoldOutlined style={{ fontSize: 18 }} />
            )}
          </Button>
          {!isMobile && <Breadcrumb items={breadcrumbItems} />}
        </div>

        <div className="header-avatar">
          <div onClick={onAvatarClicked} style={{ display: 'flex', alignItems: 'center' }}>
            <span className="name">{user?.name || 'Admin'}</span>
            <Dropdown
              overlayClassName="containerDropdown"
              menu={{
                items: menuItems,
                onClick: (info) => {
                  if (info.key === 'logout') {
                    handleShowLogout(info);
                  } else if (info.key === 'changePassword') {
                    handleShowchangePassword(info);
                  }
                },
              }}
            >
              <Avatar className="icon" icon={<UserOutlined />} />
            </Dropdown>
          </div>
          {/* <Avatar className="icon" icon={<BellFilled />} /> */}
        </div>
      </Header>
      {/* Modal Logout */}
      <Modal
        open={openModal}
        width={400}
        style={{ minHeight: '200px', borderRadius: '2px !important' }}
        onCancel={handleCancel}
        key="modal-logout"
        footer={[
          <Row justify="center" key="a">
            <Space wrap={true}>
              <Button
                loading={state.loading}
                key="back"
                style={{ minWidth: '80px', marginRight: '80px' }}
                onClick={handleCancel}
              >
                Huỷ
              </Button>
              <Button
                loading={state.loading}
                key="submit"
                className="color-primary"
                style={{ minWidth: '80px' }}
                onClick={handleLogout}
              >
                Có
              </Button>
            </Space>
          </Row>,
        ]}
      >
        <div
          style={{
            minHeight: '100px',
            width: '70%',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            paddingTop: '40px',
          }}
        >
          Bạn muốn đăng xuất khỏi hệ thống?
        </div>
      </Modal>

      <Modal open={open} onCancel={handleCancelChangePassword} key="modal-change-pass" footer={false}>
        <div className="login-form">
          <h1 className="login-header">Đổi mật khẩu</h1>
          <Form ref={formRef} name="basic" layout="vertical" onFinish={onFinish}>
            <Form.Item label="Mật khẩu cũ" name="oldPassword" rules={validation.oldPassword}>
              <Input.Password size="large" autoComplete="off" allowClear={true} />
            </Form.Item>
            <Form.Item label="Mật khẩu mới" name="newPassword" rules={validation.newPassword}>
              <Input.Password size="large" autoComplete="off" allowClear={true} />
            </Form.Item>
            <Form.Item label="Nhập lại mật khẩu mới" name="reEnterNewPassword" rules={validation.reEnterNewPassword}>
              <Input.Password size="large" autoComplete="off" allowClear={true} />
            </Form.Item>
            <Button className="color-primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
              Đổi mật khẩu
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default memo(HeaderComponent);
