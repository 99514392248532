import { BasePagingRes, IFindPaging } from 'src/services/base.model';
import localStorageHelper, { KeyStorage } from 'src/utils/localStorage';
import endpoints from './config/endpoint';
import http from './config/http';

export class BaseService<T> {
  private url: string;

  constructor(_url: string = endpoints.categories) {
    this.url = _url;
  }

  public async get<ReqType, ResType>(url: string = '', query?: ReqType) {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const { data } = await http.get<BasePagingRes<ResType>>(`${this.url}/${url}`, { params: query, headers });
    return data;
  }
  public async post<ReqType, ResType>(url: string = '', obj: ReqType) {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const { data } = await http.post<BasePagingRes<ResType>>(`${this.url}${url}`, obj, { headers });
    return data;
  }

  public async findOne(id: number, url: string = 'find') {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const res = await http.get<T>(`${this.url}/${url}/${id}`, { headers });
    return res?.data;
  }
  public async findOneAndOther(id: number, url: string = 'find') {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const res = await http.get<T>(`${url}/${id}`, { headers });
    return res?.data;
  }
  public async getById<ResType>(url: string, query?: IFindPaging) {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const { data } = await http.get<ResType>(url, { headers, params: query });
    return data;
  }

  public async findByIds(ids: number[]) {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const { data } = await http.post<T[]>(`${this.url}/find-ids/`, { ids }, { headers });
    return data;
  }

  // 192.138.1.1/api/onboard/
  public async update(id: number, obj: T) {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const { data } = await http.put<T>(`${this.url}/${id}`, obj, { headers });
    return data;
  }

  public async delete<ReqType, ResType>(id: number, obj: ReqType) {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const { data } = await http.delete<BasePagingRes<ResType>>(`${this.url}/${id}`, { headers, data: obj });
    return data;
  }

  // 192.138.1.1/api/onboard/
  public async updateStatus(id: number, obj: T) {
    const headers = { authorization: `Bearer ${localStorageHelper.get(KeyStorage.TOKEN)}` };
    const { data } = await http.put<T>(`${this.url}/change-status/${id}`, obj, { headers });
    return data;
  }
}
