import { EAccountType, IPaging } from 'src/services/base.model';

// loại tin nhắn
export enum EMessageType {
  Text = 1, // văn bản
  Image = 2, // hình ảnh
  Location = 3, // Vị trí
}

export enum SOCKET_EVENTS {
  JOIN_CHAT_WITH_ADMIN = 'JOIN_CHAT_WITH_ADMIN', // Vào phòng nhận tin nhắn chat với admin
  LEAVE_CHAT_WITH_ADMIN = 'LEAVE_CHAT_WITH_ADMIN', // Rời phòng nhận tin nhắn chat với admin
  NEW_MESSAGE_FROM_ADMIN = 'NEW_MESSAGE_FROM_ADMIN', // Tin nhắn với ADMIN
  EXCEL_SUCCESS = 'EXCEL_SUCCESS', // Import/export excel success
}

export interface IThreadChatWithAdmin {
  id: number;
  thread_id: number;
  account_name: string;
  account_phone: string;
  account_type: EAccountType;
  account_avatar?: string;
  last_message_id: number;
  message: string;
  message_type: EMessageType;
  created_at: Date;
  updated_at: Date;
}

export interface IFindThreadChatWithAdminReq extends IPaging {
  searchText?: string;
  accountType?: EAccountType;
}

export interface IChatWithAdmin {
  id: number;
  thread_id: number;
  account_id: number;
  account_name: string;
  avatar?: string;
  message: string;
  type: EMessageType;
  created_at: Date;
  updated_at: Date;
  sentId?: string;
}

export interface IFindChatWithAdminReq extends IPaging {
  searchText?: string;
}

export interface ISocketBookingChatPayload {
  message: IChatWithAdmin;
}

export interface IBodyMessage {
  type: EMessageType;
  message?: string;
  file?: any;
  sentId: string;
}
