import { ApexOptions } from 'apexcharts';
import dayJs, * as dayjs from 'dayjs';
// import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DateDMY, DateFormat, TimeDateFormatHM, TimeHmmSS } from 'src/constants/common.constants';
dayjs.extend(utc);
// dayjs.extend(timezone);

export const convertTimeDayjs = (time: string | Date, format = TimeHmmSS) => {
  const timeJs = dayJs(time).format(format);

  return timeJs;
};
export const convertTimeHMM = (time: string | Date, format = TimeDateFormatHM) => {
  const timeJs = dayJs(time).format(format);

  return timeJs;
};

export const convertDate = (time: string | Date, format = DateFormat) => {
  const timeJs = dayJs(time).format(format);

  return timeJs;
};

export const getDateToWithdraw = () => {
  const day = dayJs().format('DD');
  let fromDate = '';
  let toDate = '';
  if (Number(day) <= 10) {
    fromDate = `01/${dayJs().format('MM/YYYY')}`;
    toDate = `10/${dayJs().format('MM/YYYY')}`;
  } else if (Number(day) > 10 && Number(day) <= 20) {
    fromDate = `11/${dayJs().format('MM/YYYY')}`;
    toDate = `20/${dayJs().format('MM/YYYY')}`;
  } else {
    fromDate = `21/${dayJs().format('MM/YYYY')}`;
    toDate = dayJs().endOf('months').format(DateDMY);
  }
  return {
    fromDate: dayJs(fromDate, DateDMY),
    toDate: dayJs(toDate, DateDMY),
  };
};

export const numBetween2Day = (fromDate: string = '', toDate: string = '') => {
  if (fromDate !== '' && toDate !== '') {
    let numberDay = Number(
      ((dayJs(toDate).toDate().getTime() - dayJs(fromDate).toDate().getTime()) / (1000 * 3600 * 24)).toFixed(),
    );
    if (numberDay === 0) {
      numberDay = 1;
    }
    return numberDay;
  } else {
    return 0;
  }
};

export const countDataOfDate = (fromDate: string, toDate: string, data: any) => {
  const _data = [] as any;
  let count = 0;
  const numberDay = numBetween2Day(fromDate, toDate);
  if (numberDay === 1) {
    for (let i = 1; i <= 24; i++) {
      count = 0;
      if (data.length > 0) {
        data.forEach((item: any) => {
          if (Number(dayJs(item.createdAt).format('HH')) === i) {
            count += 1;
          }
        });
        _data.push(count);
      }
    }
    return { data: [{ data: _data }], option: { categories: '', title: { text: 'Giờ' } } };
  } else {
    let _newData = [] as any;
    for (let i = 0; i < numberDay; i++) {
      count = 0;
      data.forEach((item: any) => {
        const dayCreatedAt = dayJs(item.createdAt).format('DD/MM');
        const dayFromDate = dayJs(fromDate).add(i, 'day').format('DD/MM');
        if (dayCreatedAt === dayFromDate) {
          count += 1;
        }
      });
      _data.push({ x: dayJs(fromDate).add(i, 'day').toDate().getTime(), y: count });
      _newData = [{ data: _data }];
    }

    const _dataOption: ApexOptions['xaxis'] = {
      type: 'datetime',
      labels: {
        formatter(value: any) {
          return dayJs(value).format(DateDMY);
        },
      },
    };
    return { data: _newData, option: _dataOption };
  }
};
